import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { ComputedRef, computed } from 'vue';

import { Optional } from '@silae/helpers';
import { useBackendAppStore } from '~/stores/backend-app.store';
import { dateAsLocalDate } from '~/utils';

export function useAppVersion(): {
	date: ComputedRef<Optional<string>>;
	version: ComputedRef<string>;
} {
	const { backendHealth } = storeToRefs(useBackendAppStore());

	const getMostRecentDate = (date1: Date, date2: Date): Date => (date1 > date2 ? date1 : date2);

	const version = computed(() => `${backendHealth.value?.version}-${__APP_VERSION__}`);
	const _rawDate = computed(() =>
		backendHealth.value?.startedAt != undefined
			? getMostRecentDate(backendHealth.value?.startedAt, new Date(__APP_VERSION_DATETIME__))
			: new Date(__APP_VERSION_DATETIME__)
	);
	const date = computed(() => dateAsLocalDate(_rawDate.value, DateTime.DATE_SHORT));

	return { date, version };
}
