import { Role } from '../domain';

export const RouteKeys = {
	ACCOUNT: 'account',
	ACCOUNT_CONTRACTUAL_INFORMATION: 'account.contractual-information',
	ACCOUNT_PREFERENCES: 'account.preferences',
	ACCOUNT_SECURITY: 'account.security',
	ADMIN_AUTOMATIC_EARNED_WAGE_ACCESS: 'admin.automatic-earned-wage-access',
	ADMIN_AUTOMATIC_EARNED_WAGE_ACCESS_NO_VARIABLE_ELEMENTS_RIGHTS: 'admin.automatic-earned-wage-access-no-variable-elements-rights',
	ADMIN_EARNED_WAGE_ACCESS: 'admin.earned-wage-access',
	ADMIN_EMPLOYEE_CONTRACTS: 'admin.employee.details.contracts',
	ADMIN_EMPLOYEE_DETAILS: 'admin.employee.details',
	ADMIN_EMPLOYEE_INFORMATION: 'admin.employee.details.information',
	ADMIN_EMPLOYEE_LIFECYCLE: 'admin.employee.lifecycle',
	ADMIN_EMPLOYEE_LIST: 'admin.employee.list',
	ADMIN_EMPLOYEE_MANAGEMENT: 'admin.employees',
	ADMIN_EMPLOYEE_OFFBOARDING: 'admin.employee.offboarding',
	ADMIN_EMPLOYEE_ONBOARDING: 'admin.employee.onboarding',
	ADMIN_EMPLOYEE_PERSONAL_INFORMATION: 'admin.employee.details.personal-information',
	AGENDA: 'agenda',
	AGENDA_LEAVE_DAYS: 'agenda.leave-days',
	AGENDA_REMOTE_WORK: 'agenda.remote-work',
	AUTOMATIC_EARNED_WAGE_ACCESSES: 'automatic-earned-wage-accesses',
	CONFIGURATION: 'configuration',
	CONFIGURATION_LOGINS: 'configuration.logins',
	CONFIGURATION_TEAMS: 'configuration.teams',
	CONNECTION: 'connection',
	DOCUMENTS: 'documents',
	DOCUMENTS_ADMINISTRATIVE: 'documents.documents',
	DOCUMENTS_PAYSLIPS: 'documents.payslips',
	DOCUMENTS_SALARY_TRANSFER: 'documents.salary-transfer',
	HOME: 'home',
	IMPERSONATION: 'impersonation',
	LEAVES_AND_MOBILITIES: 'leaves-and-mobilities',
	LEAVES_AND_MOBILITIES_LEAVE_DAYS: 'leaves-and-mobilities.leave-days',
	LEAVES_AND_MOBILITIES_REMOTE_WORK: 'leaves-and-mobilities.remote-work',
	MEDICAL_CHECKUPS: 'medical-checkups',
	MEDICAL_CHECKUPS_EMPLOYEE: 'medical-checkups.employee',
	MEDICAL_CHECKUPS_LIST: 'medical-checkups.list',
	MEDICAL_CHECKUPS_SERVICES: 'medical-checkups.services',
	MEETINGS: 'meetings',
	NOT_FOUND: '404',
	PAYROLL: 'payroll',
	PAYROLL_DASHBOARD: 'payroll.dashboard',
	PAYROLL_PAYSLIPS_VALIDATION_COMPLETE: 'payroll.payslips-validation.complete',
	PAYROLL_PAYSLIPS_VALIDATION_PENDING: 'payroll.payslips-validation.pending',
	PAYROLL_VALIDATION: 'payroll.validation.variable-elements',
	PAYROLL_VALIDATION_AUTOMATIC_EARNED_WAGE_ACCESS: 'payroll.validation.automatic-earned-wage-access',
	PAYROLL_VALIDATION_EARNED_WAGE_ACCESS: 'payroll.validation.earned-wage-access',
	PAYROLL_VALIDATION_LEAVE_DAYS: 'payroll.validation.leave-days',
	PAYROLL_VALIDATION_VARIABLE_ELEMENTS_READONLY: 'payroll.validation.variable-elements.readonly',
	PAYROLL_VARIABLE_ELEMENTS: 'payroll.variable-elements',
	PAYROLL_VARIABLE_ELEMENTS_EDITION: 'payroll.variable-elements.edition',
	PUBLIC_DOWNLOAD: 'public-download',
	PUBLIC_DOWNLOAD_EXPIRED: 'public-download-expired',
	SIGN_DOCUMENTS: 'sign-documents',
	SIGN_IN: 'sign-in',
	SIGN_IN_DENARIO: 'sign-in-denario',
	UPDATE_PASSWORD: 'update-password',
	UPDATE_PASSWORD_GENERATE_CODE: 'update-password.generate-code',
	UPDATE_PASSWORD_RESET: 'update-password.reset',
	UPDATE_PASSWORD_RESET_NO_PARAMETER: 'update-password.reset.np',
	USER_SIGN: 'user-sign',
	USER_SIGN_AUTHORIZED: 'user-sign-authorized',
	USER_SIGN_UNAUTHORIZED: 'user-sign-unauthorized'
};

export type RouteContext = {
	name: string;
	role: Role;
};

/**
 * this mapping allows to address our routes from emails or external links.
 * never update a key here as it's probably hard coded somewhere outside to point to one of our pages
 */
export const RouteContextByKey: { [key: string]: RouteContext } = {
	[RouteKeys.CONFIGURATION_LOGINS]: { name: RouteKeys.CONFIGURATION_LOGINS, role: Role.ADMIN }
};
