<!-- This component is the same as the sk-dialog, but with an input selector inside. Due to a strange bundling bug, if you try to use the
sk-dialog with an input selector, the input selector will not work. The input selector will have a z-index at 2000 and will be not
visible inside the dialog (with a z-index at 2400). This is a workaround for that bug until a suitable solution is found with the
bundling of the kit. -->
<template>
	<v-dialog v-model="value" class="sk-dialog" :persistent="!closable" :width>
		<sk-card ref="dialogRef" class="sk-dialog-card">
			<template #header-title>
				<slot :close="close" name="title">
					<v-avatar v-if="icon" class="mr-xs" :color="`${level}-background`" size="48">
						<v-icon :color="level" :icon="icon" size="var(--sk-icon-size-lg)" />
					</v-avatar>
					<div v-if="title" class="sk-dialog-card-title-content text-h2-bold">{{ title }}</div>
				</slot>
			</template>

			<template #header-actions>
				<sk-close-icon-btn v-if="closable" @click="close" />
			</template>

			<template #default>
				<div class="sk-dialog-card-content">
					<div v-if="subtitle" class="text-body-lg">{{ subtitle }}</div>
					<div v-if="alertText || alertTitle" class="sk-dialog-card-content-alert">
						<div class="text-body-lg-bold sk-dialog-card-content-alert-title">{{ alertTitle }}</div>
						<div v-if="alertText" class="text-body-lg">{{ alertText }}</div>
					</div>
					<slot :close="close" />
				</div>
			</template>

			<template v-if="$slots.footer" #footer>
				<slot :close="close" name="footer" />
			</template>
		</sk-card>
	</v-dialog>
</template>

<style lang="scss" scoped>
.sk-dialog {
	&-card {
		:deep(.sk-card-body),
		:deep(.sk-card-footer) {
			gap: var(--sk-spacing-sm);
			padding: 0 var(--sk-spacing-md) var(--sk-spacing-md) !important;
		}

		:deep(.sk-card-header) {
			padding: var(--sk-spacing-md) var(--sk-spacing-md) var(--sk-spacing-sm);
		}

		:deep(.sk-card-header-end) {
			align-self: flex-start;
		}

		&-content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: var(--sk-spacing-md);
			align-self: stretch;
			&-alert {
				display: flex;
				flex-direction: column;
				gap: var(--sk-spacing-sm);
				align-self: stretch;
				&-title {
					color: var(--sk-warning-hex);
				}
			}
		}
	}
}
</style>

<script lang="ts" setup>
import { VAvatar, VDialog, VIcon } from 'vuetify/components';

import { SkCard, SkCloseIconBtn } from '@silae/components';
import { UiLevel, UiSize } from '@silae/helpers';

withDefaults(
	defineProps<{
		activator?: string;
		alertText?: string;
		alertTitle?: string;
		closable?: boolean;
		icon?: string;
		level?: UiLevel;
		subtitle?: string;
		title?: string;
		width?: string | number | UiSize;
	}>(),
	{
		closable: true,
		level: UiLevel.INFO,
		iconSize: 40,
		width: '540px'
	}
);

const value = defineModel<boolean>();
const close = () => (value.value = false);
</script>
