import { RouteRecordRaw } from 'vue-router';

import { RouteKeys } from '~/router/routes.domain';

export const SignbookExplorerRoute: RouteRecordRaw = {
	path: 'signbooks/:signbookId?',
	name: RouteKeys.USER_SIGN_AUTHORIZED,
	component: () => import('./public-sign-main.vue'),
	props: route => ({ token: route.params.token, signbookId: route.params.signbookId })
};

export const PublicSignRoute: RouteRecordRaw = {
	name: RouteKeys.USER_SIGN,
	path: '/sign/:token',
	components: {
		default: () => import('./public-sign.vue'),
		appbar: () => import('../layout/app-bar/public-app-bar.vue')
	},
	props: {
		default: route => ({ token: route.params.token })
	},
	meta: {
		public: true,
		requiresAuth: false
	},
	children: [SignbookExplorerRoute]
};

export const ExpiredPublicSignRoute: RouteRecordRaw = {
	name: RouteKeys.USER_SIGN_UNAUTHORIZED,
	path: '/sign-expired/:token',
	components: {
		default: () => import('./public-sign-expired.vue'),
		appbar: () => import('../layout/app-bar/public-app-bar.vue')
	},
	props: {
		default: route => ({ token: route.params.token })
	},
	meta: {
		public: true,
		requiresAuth: false
	}
};
