import { defineStore } from 'pinia';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { useHttpCache } from '@silae/composables';
import {
	MedicalCenterDTO,
	MedicalCheckupDTO,
	MedicalCheckupModuleStateDTO,
	listMedicalCentersForEmployeeJob$,
	listMedicalCheckups$,
	listServices$
} from '~/api';

import { Clearable } from '../store.domain.ts';

export type MedicalCheckupsStore = Clearable & {
	fetchMedicalCentersForEmployeeJob: (companyId: number, jobId: number, invalidate?: boolean) => Observable<Array<MedicalCenterDTO>>;
	fetchMedicalCheckups: (companyId: number, employeeIds: Array<number>, invalidate?: boolean) => Observable<Array<MedicalCheckupDTO>>;
	invalidateMedicalCheckups(companyId: number): void;
	fetchMedicalCheckupsForEmployee: (companyId: number, employeeId: number, invalidate?: boolean) => Observable<Array<MedicalCheckupDTO>>;
	fetchServices: (companyId: number, invalidate?: boolean) => Observable<Map<string, MedicalCheckupModuleStateDTO>>;
	services: ComputedRef<Map<string, MedicalCheckupModuleStateDTO>>;
};

export const useMedicalCheckupsStore = defineStore<'medical-checkups-store', MedicalCheckupsStore>('medical-checkups-store', () => {
	const _services: Ref<Map<string, MedicalCheckupModuleStateDTO>> = ref(new Map());
	const { cache$, clearCache } = useHttpCache<number, Map<string, MedicalCheckupModuleStateDTO>>();

	const clear = () => {
		clearCache();
		clearMedicalCenterForEmployeeCache();
		medicalCheckupsClearCache();
		clearMedicalCheckupsForEmployeeCache();
	};

	const { cache$: medicalCentersForEmployeeJobCache$, clearCache: clearMedicalCenterForEmployeeCache } = useHttpCache<
		string,
		Array<MedicalCenterDTO>
	>();

	const fetchMedicalCentersForEmployeeJob = (
		companyId: number,
		jobId: number,
		invalidate?: boolean
	): Observable<Array<MedicalCenterDTO>> => {
		const cacheKey = `${companyId}_${jobId}`;
		if (invalidate) {
			clearMedicalCenterForEmployeeCache();
		}
		return medicalCentersForEmployeeJobCache$(cacheKey, listMedicalCentersForEmployeeJob$(companyId, jobId));
	};

	const fetchServices = (companyId: number, invalidate?: boolean): Observable<Map<string, MedicalCheckupModuleStateDTO>> => {
		if (invalidate) {
			clearCache();
		}

		return cache$(
			companyId,
			listServices$(companyId).pipe(
				map(serviceMapObject => {
					const mappedServices = new Map<string, MedicalCheckupModuleStateDTO>();
					// Somehow ... a map returned from the API is not a map but an Object !
					for (const [key, value] of Object.entries(serviceMapObject)) {
						mappedServices.set(key, {
							...value,
							id: key
						});
					}
					return mappedServices;
				})
			)
		).pipe(tap(services => (_services.value = services ?? new Map())));
	};

	const {
		cache$: medicalCheckupsCache$,
		clearCache: medicalCheckupsClearCache,
		invalidate: invalidateMedicalCheckups
	} = useHttpCache<number, Array<MedicalCheckupDTO>>();

	const fetchMedicalCheckups = (
		companyId: number,
		employeeIds: Array<number>,
		invalidate?: boolean
	): Observable<Array<MedicalCheckupDTO>> => {
		if (invalidate) {
			invalidateMedicalCheckups(companyId);
		}
		return medicalCheckupsCache$(companyId, listMedicalCheckups$(companyId, employeeIds, true));
	};

	const {
		cache$: medicalCheckupsCacheForEmployee$,
		clearCache: clearMedicalCheckupsForEmployeeCache,
		invalidate: invalidateMedicalCheckupsForEmployee
	} = useHttpCache<string, Array<MedicalCheckupDTO>>();

	const fetchMedicalCheckupsForEmployee = (
		companyId: number,
		employeeId: number,
		invalidate?: boolean
	): Observable<Array<MedicalCheckupDTO>> => {
		const cacheKey = `${companyId}_${employeeId}`;
		if (invalidate) {
			invalidateMedicalCheckupsForEmployee(cacheKey);
		}
		return medicalCheckupsCacheForEmployee$(cacheKey, listMedicalCheckups$(companyId, [employeeId], false));
	};

	return {
		clear,
		fetchMedicalCentersForEmployeeJob,
		fetchMedicalCheckups,
		fetchMedicalCheckupsForEmployee,
		fetchServices,
		invalidateMedicalCheckups,
		services: computed(() => _services.value)
	};
});
