import { storeToRefs } from 'pinia';
import { ComputedRef, computed } from 'vue';

import { Optional } from '@silae/helpers';
import { Feature } from '~/api';
import { useCompaniesStore, useCompanySelectionStore, useRolesStore } from '~/stores';

export function useFeatures(): {
	hasAdministeredEmployeesAccessFeature: ComputedRef<boolean>;
	hasAdministeredEmployeesRestrictedAccessFeature: ComputedRef<boolean>;
	hasAnyAdministeredEmployeesAccess: ComputedRef<boolean>;
	hasAnyPayrollFeatures: ComputedRef<boolean>;
	hasAutomaticEarnedWageAccessesAdminFeature: ComputedRef<boolean>;
	hasAutomaticEarnedWageAccessesRequestFeature: ComputedRef<boolean>;
	hasCountersFeature: ComputedRef<boolean>;
	hasEarnedWageAccessFeature: ComputedRef<boolean>;
	hasFeature: (companyId: number, feature: Feature) => boolean;
	hasLeaveDayAdminFullFeature: ComputedRef<boolean>;
	hasLeaveDaysCreationFeature: ComputedRef<boolean>;
	hasMedicalCheckupsRetrieveFeature: ComputedRef<boolean>;
	hasMedicalCheckupsSubscribeFeature: ComputedRef<boolean>;
	hasMeetingsAdminFeature: ComputedRef<boolean>;
	hasMeetingsLoginFeature: ComputedRef<boolean>;
	hasOffboardingAdminFeature: ComputedRef<boolean>;
	hasOnboardingAdminFeature: ComputedRef<boolean>;
	hasPayslipFullCompanyViewFeature: ComputedRef<boolean>;
	hasPayslipsFeature: ComputedRef<boolean>;
	hasPayslipValidationFeature: ComputedRef<boolean>;
	hasSalaryTransferFeature: ComputedRef<boolean>;
	hasSelfOnboardingFeature: ComputedRef<boolean>;
	hasSignFeature: ComputedRef<boolean>;
	hasTeamsAndAccessAdminFeature: ComputedRef<boolean>;
	hasVariableElementsBonusesFeature: ComputedRef<boolean>;
	hasVariableElementsFeature: ComputedRef<boolean>;
	hasVariableElementsHoursFeature: ComputedRef<boolean>;
	hasVariableElementsValidationFeature: ComputedRef<boolean>;
} {
	const { companiesById } = storeToRefs(useCompaniesStore());
	const { isEmployee, isManager, isAdmin } = storeToRefs(useRolesStore());
	const { employeeCompanyId, selectedCompanyIds, adminCompanyId } = storeToRefs(useCompanySelectionStore());

	const hasFeature = (companyId: Optional<number>, feature: Feature): boolean => {
		if (companyId == undefined) {
			return false;
		}
		const company = companiesById.value.get(companyId);
		return company ? company.features.includes(feature) : false;
	};

	const hasFeatureInAnyCompany = (ids: Array<number>, feature: Feature): boolean => ids.some(id => hasFeature(id, feature));

	const hasCountersFeature = computed(
		() =>
			isEmployee.value &&
			(hasFeature(employeeCompanyId.value, Feature.LEAVE_DAY_VIEW_CREATE) ||
				hasFeature(employeeCompanyId.value, Feature.LEAVE_DAY_VIEW_READ))
	);

	const hasLeaveDaysCreationFeature = computed(
		() =>
			(isEmployee.value && hasFeature(employeeCompanyId.value, Feature.LEAVE_DAY_VIEW_CREATE)) ||
			(isManager.value && hasFeatureInAnyCompany(selectedCompanyIds.value, Feature.LEAVE_DAY_SUPERVISE))
	);

	const hasPayslipsFeature = computed(() => isEmployee.value && hasFeature(employeeCompanyId.value, Feature.PAYSLIP_VIEW));

	const hasMedicalCheckupsRetrieveFeature = computed(
		() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.MEDICAL_CHECKUPS_RETRIEVE)
	);

	const hasMedicalCheckupsSubscribeFeature = computed(
		() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.MEDICAL_CHECKUPS_SUBSCRIBE)
	);

	const hasMeetingsLoginFeature = computed(() => hasFeatureInAnyCompany(selectedCompanyIds.value, Feature.MEETINGS_LOGIN));

	// ADMIN Features
	const hasTeamsAndAccessAdminFeature = computed(() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.TEAMS_ADMIN));

	const hasLeaveDayAdminFullFeature = computed(() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.LEAVE_DAY_ADMIN_FULL));

	const hasPayslipFullCompanyViewFeature = computed(
		() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.PAYSLIP_FULL_COMPANY_VIEW)
	);

	const hasPayslipValidationFeature = computed(
		() => isAdmin.value && !hasFeature(adminCompanyId.value, Feature.PAYSLIP_NO_VALIDATION) && hasPayslipFullCompanyViewFeature.value
	);

	const hasVariableElementsBonusesFeature = computed(
		() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.VARIABLE_ELEMENTS_BONUSES)
	);

	const hasVariableElementsHoursFeature = computed(
		() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.VARIABLE_ELEMENTS_HOURS)
	);

	const hasVariableElementsValidationFeature = computed(
		() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.VARIABLE_ELEMENTS_VALIDATION)
	);

	const hasMeetingsAdminFeature = computed(() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.MEETINGS_ADMIN));

	const hasSalaryTransferFeature = computed(() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.SALARY_TRANSFER));

	const hasSignFeature = computed(
		() =>
			isAdmin.value &&
			hasFeature(adminCompanyId.value, Feature.SIGN_ADMIN) &&
			hasFeature(adminCompanyId.value, Feature.SIGN_ADMIN_OFFBOARDING_DOCUMENT)
	);
	const hasVariableElementsFeature = computed(
		() => hasVariableElementsBonusesFeature.value || hasVariableElementsHoursFeature.value || hasVariableElementsValidationFeature.value
	);

	const hasAutomaticEarnedWageAccessesAdminFeature = computed(
		() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.AUTOMATIC_EARNED_WAGE_ACCESSES_ADMIN)
	);

	const hasAutomaticEarnedWageAccessesRequestFeature = computed(
		() => isEmployee.value && hasFeature(employeeCompanyId.value, Feature.AUTOMATIC_EARNED_WAGE_ACCESSES_REQUEST)
	);

	const hasEarnedWageAccessFeature = computed(
		() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.VARIABLE_ELEMENTS_EARNED_WAGE_ACCESS)
	);

	const hasAdministeredEmployeesAccessFeature = computed(
		() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.EMPLOYEE_DETAILS)
	);

	const hasAdministeredEmployeesRestrictedAccessFeature = computed(
		() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.EMPLOYEE_DETAILS_RESTRICTED)
	);

	const hasAnyAdministeredEmployeesAccess = computed(
		() => hasAdministeredEmployeesRestrictedAccessFeature.value || hasAdministeredEmployeesAccessFeature.value
	);

	const hasOnboardingAdminFeature = computed(() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.ONBOARDING_ADMIN));
	const hasOffboardingAdminFeature = computed(() => isAdmin.value && hasFeature(adminCompanyId.value, Feature.OFFBOARDING_ADMIN));

	const hasSelfOnboardingFeature = computed(() => isEmployee.value && hasFeature(employeeCompanyId.value, Feature.SELF_ONBOARDING));

	const hasAnyPayrollFeatures = computed(() => {
		return (
			useFeatures().hasVariableElementsBonusesFeature.value ||
			useFeatures().hasVariableElementsHoursFeature.value ||
			useFeatures().hasVariableElementsValidationFeature.value ||
			useFeatures().hasEarnedWageAccessFeature.value ||
			useFeatures().hasPayslipFullCompanyViewFeature.value
		);
	});

	return {
		hasAdministeredEmployeesAccessFeature,
		hasAdministeredEmployeesRestrictedAccessFeature,
		hasAnyAdministeredEmployeesAccess,
		hasAnyPayrollFeatures,
		hasAutomaticEarnedWageAccessesAdminFeature,
		hasAutomaticEarnedWageAccessesRequestFeature,
		hasCountersFeature,
		hasEarnedWageAccessFeature,
		hasFeature,
		hasLeaveDayAdminFullFeature,
		hasLeaveDaysCreationFeature,
		hasMedicalCheckupsRetrieveFeature,
		hasMedicalCheckupsSubscribeFeature,
		hasMeetingsAdminFeature,
		hasMeetingsLoginFeature,
		hasOffboardingAdminFeature,
		hasOnboardingAdminFeature,
		hasPayslipFullCompanyViewFeature,
		hasPayslipsFeature,
		hasPayslipValidationFeature,
		hasSalaryTransferFeature,
		hasSelfOnboardingFeature,
		hasSignFeature,
		hasTeamsAndAccessAdminFeature,
		hasVariableElementsBonusesFeature,
		hasVariableElementsFeature,
		hasVariableElementsHoursFeature,
		hasVariableElementsValidationFeature
	};
}
