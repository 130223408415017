import { HalfDayOption } from '~/api';
import { PickedRemoteWork } from '~/pages/leaves-and-mobilities';

export enum DayPeriod {
	MORNING = HalfDayOption.MORNING,
	AFTERNOON = HalfDayOption.AFTERNOON,
	FULL_DAY = 'FULL_DAY'
}

export type RemoteWorkRecap = PickedRemoteWork & {
	dayPeriod: DayPeriod;
};

export const DAY_PERIOD = { label: 'Journée', value: DayPeriod.FULL_DAY };
export const MORNING_PERIOD = { label: 'Matin', value: DayPeriod.MORNING };
export const AFTERNOON_PERIOD = { label: 'Après-midi', value: DayPeriod.AFTERNOON };

export const PERIOD_OPTIONS = [DAY_PERIOD, MORNING_PERIOD, AFTERNOON_PERIOD];
