import { AxiosResponse } from 'axios';
import { Observable } from 'rxjs';

import { COMPANY_BASE_URL } from '../defs.ts';
import { useBackendHttpService } from '../http';
import { EmployeeInformationForDocSharingDTO, FileDraftUploadResponse } from './files.dto';

export function uploadFileDraft$(companyId: number, request: FormData): Observable<FileDraftUploadResponse> {
	const { http } = useBackendHttpService();
	return http.post(`${COMPANY_BASE_URL}/${companyId}/files/draft`, request, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
}

export function downloadGenericFile$(companyId: number, fileId: string): Observable<AxiosResponse<string>> {
	const { http } = useBackendHttpService();
	return http.get(`${COMPANY_BASE_URL}/${companyId}/files/${fileId}`, { responseType: 'arraybuffer' }, (res: AxiosResponse) => res);
}

export function getPersonalInformations$(companyId: number, employeeId: number): Observable<EmployeeInformationForDocSharingDTO> {
	const { http } = useBackendHttpService();
	return http.get(`${COMPANY_BASE_URL}/${companyId}/public-sharing/employees/${employeeId}`);
}
