export function isValidFrenchSSN(value: string): boolean {
	if (!value) {
		return true; // Allow empty values, control with 'required' rule if needed and not with this function!
	}
	// Remove spaces and convert to uppercase
	const cleanValue = value.replace(/\s/g, '').toUpperCase();

	if (cleanValue.length !== 15) {
		return false;
	}

	// Replace potientialy Corsican department codes
	const SSN = cleanValue.slice(0, 15).replace(/2A/i, '19').replace(/2B/i, '18');

	// Check if the number is 15 digits long
	if (!/^\d{15}$/.test(SSN)) {
		return false;
	}

	const gender = parseInt(SSN.slice(0, 1), 10);
	if (gender !== 1 && gender !== 2) {
		return false; // Check gender (1 or 2)
	}

	const numWithoutKey = SSN.slice(0, 13); // Number without key
	const key = parseInt(SSN.slice(13, 15)); // Control key
	const expectedKey = 97 - (parseInt(numWithoutKey, 10) % 97);

	return key === expectedKey; // Check control key
}

export function matchBirthdateToFrenchSSN(value?: string, birthdate?: Date): boolean {
	if (!value || !birthdate) {
		return true; // Allow empty values, control with 'required' rule if needed
	}

	// Remove spaces and convert to uppercase
	const cleanValue = value.replace(/\s/g, '').toUpperCase();

	// Extract from the SSN
	const year = parseInt(cleanValue.slice(1, 3), 10); // Year of birth
	const month = parseInt(cleanValue.slice(3, 5), 10); // Month of birth

	// Validate date coherence
	const birthYear = birthdate.getFullYear() % 100; // Last two digits of birth year
	const birthMonth = birthdate.getMonth() + 1; // Months are indexed from 0, so we add 1.

	return year === birthYear && month === birthMonth;
}

export function matchDepartmentOfBirthToFrenchSSN(value?: string, department?: string): boolean {
	if (!value || !department) {
		return true; // Allow empty values, control with 'required' rule if needed
	}

	// Remove spaces and convert to uppercase
	const cleanValue = value.replace(/\s/g, '').toUpperCase();

	const departmentCodeInSSN = cleanValue.slice(5, 7);
	// DOM-TOM and some codes are not checked to be Silae Pay compliant.
	const ignoredCodes = ['96', '97', '98', '99'];

	if (ignoredCodes.includes(departmentCodeInSSN)) {
		return true;
	}

	return departmentCodeInSSN === department;
}

export function isValidIban(value: string): boolean {
	if (!value) {
		return true; // Allow empty values, control with 'required' rule if needed
	}

	// Remove spaces and convert to uppercase
	const iban = value.replace(/\s/g, '').toUpperCase();

	// Control the length of the IBAN
	if (iban.length > 34) {
		return false;
	}

	// General format : 2 letters, 2 digits, and then any number of letters and digits
	if (!/^[A-Z]{2}\d{2}[A-Z0-9]+$/.test(iban)) {
		return false;
	}

	// IBAN lengths by country
	const ibanLengths: Record<string, number> = {
		BE: 16,
		CH: 21,
		DE: 22,
		ES: 24,
		FR: 27,
		GB: 22,
		IT: 27,
		LU: 20,
		MC: 27,
		NL: 18,
		PT: 25,
		SE: 24
	};

	const countryCode = iban.substring(0, 2);

	if (ibanLengths[countryCode] && iban.length !== ibanLengths[countryCode]) {
		return false;
	}

	// Check IBAN integrity with modulo 97 (ISO 7064)
	// 1. Move the first 4 characters to the end of the string
	const rearrangedIban = iban.substring(4) + iban.substring(0, 4);
	// 2. Convert letters to numbers. Each letters is replaced by its position in the alphabet + 9 : A=10, B=11, ..., Z=35
	const numericIban = rearrangedIban
		.split('')
		.map(char => (char >= 'A' && char <= 'Z' ? char.charCodeAt(0) - 55 : char))
		.join('');
	// 3. Check if the remainder of the division by 97 is 1
	return BigInt(numericIban) % BigInt(97) === BigInt(1);
}

export function isValidBic(bic: string, iban?: string): boolean {
	if (!bic) {
		return true; // Allow empty values, control with 'required' rule if needed
	}

	if (iban) {
		// Check if the BIC is valid for the IBAN country
		const countryCode = iban.substring(0, 2);
		const bicCountryCode = bic.substring(4, 6);
		if (countryCode !== bicCountryCode) {
			return false;
		}
	}

	// BIC format : 8 or 11 characters, only letters and digits
	const regex = /^[A-Za-z0-9]{8}([A-Za-z0-9]{3})?$/;
	return regex.test(bic);
}
