import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { $i } from '@silae/composables';
import { useFeatures } from '~/composables';
import { RouteKeys } from '~/router/routes.domain';
import { useMeetingStore } from '~/stores';

import { defaultLayoutComponents } from '../layout/layout';

export const MeetingsRoute: RouteRecordRaw = {
	name: RouteKeys.MEETINGS,
	path: '/meetings',
	components: {
		default: () => import('./meetings.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => {
			const { isMeetingRouteForbidden } = storeToRefs(useMeetingStore());
			const { hasSelfOnboardingFeature } = useFeatures();
			return computed(() => isMeetingRouteForbidden.value || hasSelfOnboardingFeature.value);
		},
		label: 'interview.title',
		icon: () => $i('messages-round'),
		isNavEntry: true,
		order: 30,
		requiresAuth: true
	}
};
