import { acceptHMRUpdate, defineStore } from 'pinia';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ComputedRef, Ref, computed, ref, toRefs } from 'vue';

import { useHttpCache } from '@silae/composables';
import {
	AdminLeaveDaysSearchRequest,
	LeaveDaysDTO,
	PeriodRequest,
	listAdministeredLeaveDaysInPeriod$,
	listAdministeredPayrollLeaveDays$,
	listLeaveDaysInPeriod$,
	listManagedLeaveDaysInPeriod$
} from '~/api';
import { useLeaveDays } from '~/composables/leave-days.composables';
import { PrettyLeaveDays, Role } from '~/domain';

import { useRolesStore } from '../roles.store';
import { Clearable } from '../store.domain.ts';

export type LeaveDaysStore = Clearable & {
	leaveDaysInPeriod: ComputedRef<Array<PrettyLeaveDays>>;
	allLeaveDaysAndRemoteWorksInPeriodForDatePicker: ComputedRef<Array<PrettyLeaveDays>>;
	fetchLeaveDaysInPeriod$: (
		companyIds: Array<number>,
		periodRequest: PeriodRequest,
		invalidateCache?: boolean
	) => Observable<Array<PrettyLeaveDays>>;
	fetchAllLeaveDaysAndRemoteWorksInPeriodForDatePicker$: (
		companyIds: Array<number>,
		periodRequest: PeriodRequest,
		invalidateCache?: boolean
	) => Observable<Array<PrettyLeaveDays>>;
	payrollLeaveDays: ComputedRef<Array<PrettyLeaveDays>>;
	fetchPayrollLeaveDays$: (
		companyIds: number,
		request: AdminLeaveDaysSearchRequest,
		invalidateCache?: boolean
	) => Observable<Array<PrettyLeaveDays>>;
};

export const useLeaveDaysStore = defineStore<'leave-days-store', LeaveDaysStore>('leave-days-store', (): LeaveDaysStore => {
	const { asPrettyLeaveDays } = useLeaveDays();

	const _leaveDaysInPeriod: Ref<Array<PrettyLeaveDays>> = ref([]);
	const _allLeaveDaysAndRemoteWorksInPeriodForDatePicker: Ref<Array<PrettyLeaveDays>> = ref([]);
	const leaveDaysInPeriod: ComputedRef<Array<PrettyLeaveDays>> = computed(() => _leaveDaysInPeriod.value);
	const _payrollLeaveDays: Ref<Array<PrettyLeaveDays>> = ref([]);
	const payrollLeaveDays: ComputedRef<Array<PrettyLeaveDays>> = computed(() => _payrollLeaveDays.value);

	const { cache$: employeeCache$, clearCache: clearEmployeeCache } = useHttpCache<string, Array<LeaveDaysDTO>>();
	const { cache$: managerCache$, clearCache: clearManagerCache } = useHttpCache<string, Array<LeaveDaysDTO>>();
	const { cache$: adminCache$, clearCache: clearAdminCache } = useHttpCache<string, Array<LeaveDaysDTO>>();
	const { cache$: adminPayrollCache$, clearCache: clearPayrollLeaveDaysCache } = useHttpCache<string, Array<LeaveDaysDTO>>();

	const clear = () => {
		clearEmployeeCache();
		clearManagerCache();
		clearAdminCache();
		clearPayrollLeaveDaysCache();
	};

	const _fetchInPeriod$ = (companyIds: Array<number>, periodRequest: PeriodRequest, invalidateCache?: boolean) => {
		const { activeRole } = useRolesStore();

		if (invalidateCache) {
			clear();
		}

		const computeKey = (companyIds: Array<number>, periodRequest: PeriodRequest): string =>
			`companyIds[${companyIds.join('-')}].${periodRequest.groupType}.${periodRequest.start}.${periodRequest.end}`;

		let request$: (companyIds: Array<number>, periodRequest: PeriodRequest) => Observable<Array<LeaveDaysDTO>>;

		switch (activeRole) {
			case Role.EMPLOYEE:
				request$ = (companyIds: Array<number>, periodRequest: PeriodRequest) =>
					employeeCache$(computeKey(companyIds, periodRequest), listLeaveDaysInPeriod$(companyIds, periodRequest));
				break;
			case Role.MANAGER:
				request$ = (companyIds: Array<number>, periodRequest: PeriodRequest) =>
					managerCache$(computeKey(companyIds, periodRequest), listManagedLeaveDaysInPeriod$(companyIds, periodRequest));
				break;
			case Role.ADMIN:
				request$ = (companyIds: Array<number>, periodRequest: PeriodRequest) =>
					adminCache$(
						computeKey(companyIds, periodRequest),
						listAdministeredLeaveDaysInPeriod$(companyIds[0], { ...periodRequest })
					);
				break;
		}

		return request$(companyIds, periodRequest).pipe(map(leaveDaysDTOs => leaveDaysDTOs.map(asPrettyLeaveDays)));
	};

	const _fetchLeaveDaysInPeriod$ = (
		companyIds: Array<number>,
		periodRequest: PeriodRequest,
		invalidateCache?: boolean
	): Observable<Array<PrettyLeaveDays>> =>
		_fetchInPeriod$(companyIds, periodRequest, invalidateCache).pipe(tap(leaveDays => (_leaveDaysInPeriod.value = leaveDays)));

	const _fetchAllLeaveDaysAndRemoteWorksInPeriodForDatePicker$ = (
		companyIds: Array<number>,
		periodRequest: PeriodRequest,
		invalidateCache?: boolean
	): Observable<Array<PrettyLeaveDays>> =>
		_fetchInPeriod$(companyIds, periodRequest, invalidateCache).pipe(
			tap(leaveDays => (_allLeaveDaysAndRemoteWorksInPeriodForDatePicker.value = leaveDays))
		);
	const _fetchPayrollLeaveDays$ = (companyId: number, request: AdminLeaveDaysSearchRequest, invalidateCache?: boolean) => {
		const { isAdmin } = toRefs(useRolesStore());

		if (invalidateCache) {
			clear();
		}

		const computeKey = (companyId: number, request: AdminLeaveDaysSearchRequest): string =>
			`payroll-companyId[${companyId}].${request.mode}.${request.period}.employees[${request.employeeIds?.join('-')}]`;

		if (isAdmin.value) {
			return adminPayrollCache$(computeKey(companyId, request), listAdministeredPayrollLeaveDays$(companyId, request)).pipe(
				map(leaveDaysDTOs => leaveDaysDTOs.map(asPrettyLeaveDays)),
				tap(leaveDays => (_payrollLeaveDays.value = leaveDays))
			);
		}

		return of([]);
	};

	return {
		leaveDaysInPeriod,
		allLeaveDaysAndRemoteWorksInPeriodForDatePicker: computed(() => _allLeaveDaysAndRemoteWorksInPeriodForDatePicker.value),
		fetchLeaveDaysInPeriod$: _fetchLeaveDaysInPeriod$,
		fetchAllLeaveDaysAndRemoteWorksInPeriodForDatePicker$: _fetchAllLeaveDaysAndRemoteWorksInPeriodForDatePicker$,
		payrollLeaveDays,
		fetchPayrollLeaveDays$: _fetchPayrollLeaveDays$,
		clear
	};
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useLeaveDaysStore, import.meta.hot));
