import { ISODateString, ISODateTimeString } from '../defs';
import { FileMetadata } from '../files';
import { LeaveDaysTypeFamily } from './types/leave-days-types.dto';

export enum AdminLeaveDaysSearchMode {
	PAYROLL = 'PAYROLL'
}

export enum LeaveDaysState {
	PENDING = 'PENDING',
	PENDING_FOR_MODIFICATION = 'PENDING_FOR_MODIFICATION',
	PENDING_FOR_CANCELLATION = 'PENDING_FOR_CANCELLATION',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
	CANCELED = 'CANCELED',
	INTEGRATED = 'INTEGRATED',
	DELETED = 'DELETED'
}

export enum LeaveDaysDateOptions {
	FULL = 'FULL',
	HALFDAY = 'HALFDAY',
	HOURS = 'HOURS'
}

export enum HalfDayOption {
	MORNING = 'MORNING',
	AFTERNOON = 'AFTERNOON'
}

export enum LeaveDaysWorkflowEvent {
	SUBMIT = 'SUBMIT',
	MANAGER_CREATION = 'MANAGER_CREATION',
	ADMIN_CREATION = 'ADMIN_CREATION',
	UPDATE = 'UPDATE',
	DELETE = 'DELETE',
	REJECT = 'REJECT',
	APPROVE = 'APPROVE',
	SUBMIT_CANCELLATION = 'SUBMIT_CANCELLATION',
	REJECT_CANCELLATION = 'REJECT_CANCELLATION',
	APPROVE_CANCELLATION = 'APPROVE_CANCELLATION',
	CANCEL = 'CANCEL',
	SUBMIT_MODIFICATION = 'SUBMIT_MODIFICATION',
	REJECT_MODIFICATION = 'REJECT_MODIFICATION',
	APPROVE_MODIFICATION = 'APPROVE_MODIFICATION',
	CREATE_PAYSLIP = 'CREATE_PAYSLIP',
	DELETE_PAYSLIP = 'DELETE_PAYSLIP'
}

export type LeaveDaysDTO = {
	approvedAt?: ISODateTimeString;
	approvedBy: string;
	belongsToPrincipal: boolean;
	comment: string;
	companyId?: number; // this is optional as it's populated only when in manager views
	employeeFirstName: string;
	employeeId: number;
	employeeLastName: string;
	employeeName: string;
	end: ISODateString;
	externalId: number; // legacy id généré par la paie
	files?: Array<FileMetadata>;
	halfDayOption?: HalfDayOption;
	history: Array<LeaveDaysHistory>;
	hours: number;
	id: string; // id généré par le back MySilae
	isApprovable: boolean;
	isCancellable: boolean;
	isDeletable: boolean;
	isHalfDay: boolean;
	isHours: boolean;
	isInPayslip: boolean;
	isRemoteWork: boolean;
	isRestricted: boolean;
	isUpdatable: boolean;
	label: string;
	requestedAt: ISODateTimeString;
	requestedBy: string;
	start: ISODateString;
	status: LeaveDaysState;
	typeCode: string;
	typeGroupLabel: LeaveDaysTypeFamily;
	uuid: string; // unique uuid généré par la paie
};

export type LeaveDaysHistory = {
	status: LeaveDaysState;
	comment: string;
	actor: { lastname: string; firstname: string };
	event: LeaveDaysWorkflowEvent;
	date: ISODateTimeString;
	isHalfDay: boolean;
	halfDayOption: HalfDayOption;
	isHours: boolean;
	start: ISODateString;
	end: ISODateString;
	dateOption: LeaveDaysDateOptions;
	typeLabel: string;
	typeCode: string;
	typeGroupLabel: LeaveDaysTypeFamily;
	hours: string;
};

export type LeaveDaysCreationRequest = {
	typeCode: string;
	typeLabel: string;
	typeGroupLabel: string;
	comments?: string;
	companyId: number;
	employeeId: number;
	end: ISODateString;
	isEntryInHour: boolean;
	isHalfDay: boolean;
	start: ISODateString;
	dateOption: LeaveDaysDateOptions;
	hours?: string;
	fileIds?: Array<string>;
	halfDayOption?: HalfDayOption;
};

export type LeaveDaysUpdateRequest = LeaveDaysCreationRequest & {
	leaveDayUuid: string;
};

export type LeaveDayIdsRequest = {
	leaveDayUuids: Array<string>;
};

export type LeaveDaysBulkDecisionRequest = {
	comments?: string;
	leaveDayUuids: Array<string>;
};

export type LeaveDaysAdministrationDto = {
	comments?: string;
	companyId: number;
	leaveDayUuid: string;
	employeeId?: number;
};

export type EmployeeLeaveDaysRequest = {
	employeeId: number;
	leaveDaysIds: Array<number>;
};

export type AdminLeaveDaysSearchRequest = {
	employeeIds?: Array<number>;
	period: ISODateString;
	mode: AdminLeaveDaysSearchMode;
};

export type LeaveDaysBulkActionsAsAdminRequest = {
	employeeLeaveDaysRequests: Array<EmployeeLeaveDaysRequest>;
	sendValidationEmail: boolean;
	returnEmailToModify: boolean;
};
