import type { AxiosError } from 'axios';
import { storeToRefs } from 'pinia';
import { Observable, catchError, concatMap, from, of, tap, toArray } from 'rxjs';
import { map } from 'rxjs/operators';
import { ComputedRef, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { Optional } from '@silae/helpers';
import {
	ApiError,
	HalfDayOption,
	ISODateString,
	LeaveDaysCreationRequest,
	LeaveDaysDateOptions,
	LeaveDaysTypeFamily,
	RemoteWorkConfigurationDTO,
	UserRemoteWorkConfigurationDTO
} from '~/api';
import { useCompaniesInformation, usePersonalLeaveDaysCounters, useSelfManager, useToasts, useTracking } from '~/composables';
import { useCompanySelectionStore, useRolesStore } from '~/stores';

import { useLeaveDaysOperations } from '../../leave-days';
import { REMOTE_WORK_TYPE_CODE, REMOTE_WORK_TYPE_LABEL } from '../remote-work.domain.ts';
import { PickedRemoteWork } from './remote-work-form.composables.ts';

export interface RemoteWorkRequestPayload {
	companyId: Optional<number>;
	employeeId: Optional<number>;
	remoteWorks: Array<PickedRemoteWork>;
}

export function useRemoteWork(): {
	handleRemoteWorkRequest: (payload: RemoteWorkRequestPayload) => Observable<boolean>;
	isSuccess: ComputedRef<boolean>;
} {
	const { t } = useI18n();
	const { isAdmin, isManager } = storeToRefs(useRolesStore());
	const { employeeCompanyId } = storeToRefs(useCompanySelectionStore());
	const { isSelfManager } = useSelfManager(employeeCompanyId);

	const { success, error } = useToasts();
	const { track } = useTracking();
	const { fetchCounters } = usePersonalLeaveDaysCounters();

	const { createLeaveDays$ } = useLeaveDaysOperations();

	const _isSuccess = ref<boolean>(false);

	const _isRunning = ref<boolean>(false);

	const handleRemoteWorkRequest = (payload: RemoteWorkRequestPayload): Observable<boolean> => {
		if (!payload.remoteWorks?.length || _isRunning.value) {
			return of(false);
		}

		_isRunning.value = true;
		_isSuccess.value = false;
		const requests = payload.remoteWorks.map(remoteWork => {
			if (payload.companyId && payload.employeeId) {
				return getRemoteWorkRequest(
					payload.companyId,
					payload.employeeId,
					remoteWork.date,
					remoteWork.comment,
					remoteWork.isHalfDay,
					remoteWork.halfDayOption
				);
			}
		});

		const toCreateRemoteWorkRequest = (request: Optional<LeaveDaysCreationRequest>): Observable<boolean> => {
			if (!request) return of(false);

			return createLeaveDays$(request).pipe(
				map(() => true),
				catchError((err: AxiosError<ApiError>) => {
					handleError(err);
					return of(false);
				})
			);
		};

		return from(requests).pipe(
			concatMap(toCreateRemoteWorkRequest),
			toArray(),
			tap(successesOrFail => {
				_isRunning.value = false;
				_isSuccess.value = successesOrFail.every(success => success);
				track('Remote Created', { companyId: payload.companyId }, { total_of_days: payload.remoteWorks?.length });
				if (_isSuccess.value) {
					handleBatchSuccess(payload);
				}
			}),
			map(() => _isSuccess.value)
		);
	};

	function computeTitle(): string {
		return isManager.value || isAdmin.value || isSelfManager.value
			? t('remote_work.new_request.feedback.success.title_as_manager')
			: t('remote_work.new_request.feedback.success.title');
	}

	function handleBatchSuccess(payload: RemoteWorkRequestPayload) {
		success({
			title: computeTitle()
		});

		if (!payload.companyId) return;

		fetchCounters(payload.companyId, { invalidate: true });
	}

	function handleError(err: AxiosError<ApiError>) {
		error({
			title: t('remote_work.error.title'),
			text: err.response?.data.error || t('error.unknown')
		});
	}

	return {
		handleRemoteWorkRequest,
		isSuccess: computed(() => _isSuccess.value)
	};
}

function getRemoteWorkRequest(
	companyId: number,
	employeeId: number,
	date: ISODateString,
	comments?: string,
	isHalfDay?: boolean,
	halfDayOption?: HalfDayOption
): LeaveDaysCreationRequest {
	return {
		comments: comments,
		companyId,
		employeeId,
		start: date,
		end: date, // each date corresponds to a single-day remote work
		dateOption: isHalfDay ? LeaveDaysDateOptions.HALFDAY : LeaveDaysDateOptions.FULL,
		isEntryInHour: false,
		isHalfDay: isHalfDay ?? false,
		halfDayOption: halfDayOption,
		typeCode: REMOTE_WORK_TYPE_CODE, // custom value from Silae Paie
		typeGroupLabel: LeaveDaysTypeFamily.REMOTEWORK,
		typeLabel: REMOTE_WORK_TYPE_LABEL // custom value from Silae Paie
	};
}

export function useRemoteWorkConfiguration(): {
	getRemoteWorkConfigurationByCompany: (companyId: number) => Optional<RemoteWorkConfigurationDTO>;
	getEmployeeRemoteWorkConfigurationByCompanyIdAndEmployeeId: (
		companyId: number,
		employeeId: number
	) => Optional<UserRemoteWorkConfigurationDTO>;
	canRequestRemoteWork: ComputedRef<boolean>;
} {
	const { isManager, isAdmin } = storeToRefs(useRolesStore());
	const { informationPerCompany } = useCompaniesInformation();

	const getRemoteWorkConfigurationByCompany = (companyId: number) => {
		const _companyInformation = informationPerCompany.value.get(companyId);

		if (!_companyInformation?.remoteWorkConfiguration?.employeeConfigurations) {
			return undefined;
		}

		return _companyInformation?.remoteWorkConfiguration;
	};

	const getEmployeeRemoteWorkConfigurationByCompanyIdAndEmployeeId = (companyId: number, employeeId: number) => {
		const _companyInformation = informationPerCompany.value.get(companyId);

		if (!_companyInformation?.remoteWorkConfiguration?.employeeConfigurations) {
			return undefined;
		}

		const employeesConfiguration = _companyInformation.remoteWorkConfiguration.employeeConfigurations;
		// If the user is a manager or admin, return the matching configuration for the given employeeId
		if (isManager.value || isAdmin.value) {
			return employeesConfiguration.find(config => config.employeeId === employeeId);
		}

		return employeesConfiguration[0];
	};

	const canRequestRemoteWork = computed(() =>
		Array.from(informationPerCompany.value.values()).some(companyInformation => companyInformation.remoteWorkConfiguration.hasAccess)
	);

	return {
		getRemoteWorkConfigurationByCompany,
		getEmployeeRemoteWorkConfigurationByCompanyIdAndEmployeeId,
		canRequestRemoteWork
	};
}
