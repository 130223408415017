import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

import { VariableElementType } from '../variable-elements';

export const PayrollValidationLeaveDaysRoute: RouteRecordRaw = {
	name: RouteKeys.PAYROLL_VALIDATION_LEAVE_DAYS,
	path: 'leave-days',
	component: () => import('./leave-days-validation.vue'),
	meta: {
		isForbidden: () => computed(() => Devices().isMobile || !useFeatures().hasVariableElementsValidationFeature.value)
	}
};

export const PayrollValidationEarnedWageAccessRoute: RouteRecordRaw = {
	name: RouteKeys.PAYROLL_VALIDATION_EARNED_WAGE_ACCESS,
	path: 'earned-wage-access',
	component: () => import('./earned-wage-access-validation.vue'),
	meta: {
		isForbidden: () => computed(() => Devices().isMobile || !useFeatures().hasVariableElementsValidationFeature.value)
	}
};

export const PayrollValidationAutomaticEarnedWageAccessRoute: RouteRecordRaw = {
	name: RouteKeys.PAYROLL_VALIDATION_AUTOMATIC_EARNED_WAGE_ACCESS,
	path: 'automatic-earned-wage-access',
	component: () => import('./automatic-earned-wage-access-validation.vue'),
	meta: {
		isForbidden: () =>
			computed(
				() =>
					Devices().isMobile ||
					!useFeatures().hasVariableElementsValidationFeature.value ||
					!useFeatures().hasAutomaticEarnedWageAccessesAdminFeature.value
			)
	}
};

export const VariableElementsReadonlyRoute: RouteRecordRaw = {
	name: RouteKeys.PAYROLL_VALIDATION_VARIABLE_ELEMENTS_READONLY,
	path: ':type?',
	component: () => import('./variable-elements-validation.vue'),
	props: route => ({ type: route.params.type })
};

export const PayrollValidationRoute: RouteRecordRaw = {
	name: RouteKeys.PAYROLL_VALIDATION,
	path: 'validation',
	components: {
		default: () => import('./payroll-validation.vue'),
		...defaultLayoutComponents
	},
	children: [
		VariableElementsReadonlyRoute,
		PayrollValidationLeaveDaysRoute,
		PayrollValidationEarnedWageAccessRoute,
		PayrollValidationAutomaticEarnedWageAccessRoute
	],
	redirect: _ => ({ ...VariableElementsReadonlyRoute, params: { type: VariableElementType.BONUSES } }),
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			const features = useFeatures();
			return computed(() => Devices().isMobile || !isAdmin.value || !features.hasVariableElementsValidationFeature.value);
		},
		isSubEntry: true,
		label: 'admin.navigation.variable-elements.validation',
		requiresAuth: true,
		order: 30
	}
};
