import { watchImmediate } from '@vueuse/core';
import { ComputedRef, Ref, computed, ref, toRef } from 'vue';
import { RouteLocationNormalizedLoaded, RouteRecordRaw, useRouter } from 'vue-router';

import { mapBy } from '@silae/helpers';

// TODO pretty sure this should go into a store
export function useGuardedRoutes(): {
	authorizedRoutes: ComputedRef<Array<RouteRecordRaw>>;
	authorizedRoutesByName: ComputedRef<Map<string, RouteRecordRaw>>;
	forbiddenRoutes: ComputedRef<Array<RouteRecordRaw>>;
	isForbidden: (route: RouteLocationNormalizedLoaded) => boolean;
} {
	const router = useRouter();
	const allRoutes = router.getRoutes().filter(r => r.aliasOf == null);
	const forbiddenRoutesByIdComputedRefs = allRoutes.reduce(
		(map, route) => (route.meta?.isForbidden ? { ...map, [route.name as string]: route.meta.isForbidden() } : map),
		{} as Record<string, ComputedRef<boolean>>
	);

	const authorizedRoutes = computed(() => allRoutes.filter(route => !forbiddenRoutesByIdComputedRefs?.[route.name as string]?.value));
	const authorizedRoutesByName = computed(() => mapBy(authorizedRoutes.value, 'name'));
	const forbiddenRoutes = computed(() => allRoutes.filter(route => forbiddenRoutesByIdComputedRefs?.[route.name as string]?.value));

	const isForbidden = (route: RouteLocationNormalizedLoaded) =>
		route.matched.some(match => forbiddenRoutes.value?.some(forbiddenRoute => match.name === forbiddenRoute.name));

	return { authorizedRoutes, authorizedRoutesByName, forbiddenRoutes, isForbidden };
}

/***
 synchronize the property with name ${key} from the ${props} with the current route query param of name ${param}
 example:
 ```
 	const props = defineProps<{ time: number }>
 	const { value: timeRef } = useQueryParamSynchronizedProp(props, 'time', 't');
	 // updating timeRef value will update the query param `t` in the current route URL
 ```

 */
export function useQueryParamSynchronizedProp<Val = any, Props = any>(
	props: Readonly<Props>,
	key: keyof Props,
	param: string
): {
	value: Ref<Val>;
} {
	const source = toRef(props, key);
	const value = ref();

	const { replace, currentRoute } = useRouter();

	watchImmediate(source, src => (value.value = src));
	watchImmediate(value, (val, former) => {
		if (val != null && val !== former) {
			replace({ ...currentRoute, query: { ...currentRoute.value?.query, [param]: val } });
		}
	});

	return { value };
}

export function getQueryParamsFromRedirectUrl(url: string): Record<string, string> {
	const queryParams = url?.split('?')[1];

	const params = new URLSearchParams(queryParams);

	return Object.fromEntries(params.entries());
}
